import { Show } from "solid-js";

import "./CommonIntroduction.css";

import IconChevronLeft from "~/img/icons/chevron_left.svg";
import IconEdit from "~/img/icons/edit.svg";
import IconSchedule from "~/img/icons/schedule.svg";
import { mayHaveShortcode } from "~/components/shared/Autopromos/AutopromosRenderer";

type CommonIntroductionProps = {
  title: string;
  catchline: string;
  link?: string;
  linkText?: string;
  publishedDate?: string;
  shortDate?: boolean;
  readingTime?: string;
};

export default function CommonIntroduction(props: CommonIntroductionProps) {
  return (
    <>
      <div data-test="introduction">
        <Show
          when={props.link && props.linkText}
          fallback={
            <button
              type="button"
              class="btn back-link"
              onClick={() => window.history.back()}
              aria-label="Retour"
              data-test="btn-back"
            >
              <i aria-hidden="true" class="cog-icon">
                <IconChevronLeft />
              </i>
              <span>Retour</span>
            </button>
          }
        >
          <a href={props.link} class="btn back-link" data-test="btn-back">
            <i aria-hidden="true" class="cog-icon">
              <IconChevronLeft />
            </i>
            <span>{props.linkText}</span>
          </a>
        </Show>

        <h1>{props.title}</h1>

        <Show when={props.publishedDate || props.readingTime}>
          <div class="submitted">
            <Show when={props.publishedDate}>
              <span class="item" data-test="date">
                <Show when={!props.shortDate}>
                  <i aria-hidden="true" class="cog-icon">
                    <IconEdit />
                  </i>
                  Publié le{" "}
                </Show>
                {props.publishedDate}
              </span>
            </Show>
            <Show when={props.readingTime}>
              <span class="item" data-test="reading">
                <i aria-hidden="true" class="cog-icon">
                  <IconSchedule />
                </i>
                Lecture {props.readingTime}
              </span>
            </Show>
          </div>
        </Show>

        <Show when={!mayHaveShortcode(props.catchline)}>
          <p class="page-catchline" data-test="catchline">
            {props.catchline}
          </p>
        </Show>
      </div>
    </>
  );
}
