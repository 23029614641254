import imgProxy from "~/utils/imgproxy";
import { Show } from "solid-js";
import { urlRs } from "~/utils/url";
import { mayHaveShortcode } from "~/components/shared/Autopromos/AutopromosRenderer";

import type { RelatedContent } from "~/types/drupal_jsonapi";

import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";

import "~/components/Cards/CardRelated.css";

export default function CardRelated(props: { content: RelatedContent }) {
  return (
    <>
      <article data-test="card-related" class="node-card-related">
        <Show
          when={
            props.content.type === "node--article" ||
            props.content.type === "node--journey"
          }
        >
          <picture>
            <img
              src={imgProxy(
                props.content.field_image.uri?.url
                  ? props.content.field_image.uri?.url
                  : "/images/default-related-visual.png",
                `size:327:140/resizing_type:fill`,
              )}
              alt=""
              height="80"
              width="188"
            />
          </picture>
          <div class="content-related">
            <h3>{props.content.title}</h3>
            <Show
              when={
                props.content.field_catchline &&
                !mayHaveShortcode(props.content.field_catchline)
              }
            >
              <p data-test="catchline">
                {props.content.field_catchline.slice(0, 70)}...
              </p>
            </Show>
            <a
              href={urlRs(
                props.content.type === "node--article"
                  ? "articles"
                  : "journeys",
                props.content.path.alias,
              )}
              title={`Lire la suite de ${props.content.title}`}
            >
              <i aria-hidden="true" class="cog-icon">
                <IconKeyboardArrowRight />
              </i>
              <span class="legend">Lire la suite</span>
            </a>
          </div>
        </Show>
      </article>
    </>
  );
}
